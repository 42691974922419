// extracted by mini-css-extract-plugin
export var alignDiscLeft = "B_tM d_fp d_bG d_dv";
export var alignLeft = "B_rd d_fp d_bG d_dv";
export var alignDiscCenter = "B_tN d_fq d_bD d_dw";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignDiscRight = "B_tP d_fr d_bH d_dx";
export var alignRight = "B_rf d_fr d_bH d_dx";
export var footerContainer = "B_tQ d_dW d_bW";
export var footerContainerFull = "B_tR d_dT d_bW";
export var footerHeader = "B_kf d_kf";
export var footerTextWrapper = "B_tS d_w";
export var footerDisclaimerWrapper = "B_km d_km d_ck";
export var badgeWrapper = "B_tT d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "B_tV d_bz d_bJ d_bN d_bL";
export var wide = "B_tW d_cy";
export var right = "B_tX d_bK";
export var line = "B_fk d_fl d_cv";
export var badgeDisclaimer = "B_tY d_bC d_bP d_bJ";
export var badgeContainer = "B_tZ d_bz d_bH d_bP";
export var badge = "B_t0";
export var padding = "B_t1 d_c7";
export var end = "B_t2 d_bH";
export var linkWrapper = "B_t3 d_dB";
export var link = "B_mC d_dB";
export var colWrapper = "B_t4 d_cx";
export var consent = "B_f d_f d_bC d_bP";
export var disclaimer = "B_t5 d_bz d_bJ";
export var media = "B_t6 d_bx d_dy";
export var itemRight = "B_t7";
export var itemLeft = "B_t8";
export var itemCenter = "B_t9";
export var exceptionWeight = "B_sS D_vT";