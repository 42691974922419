// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "y_s5 d_bD";
export var datasheetRowWrapper = "y_lt d_lt d_bK";
export var datasheetLeftWrapper = "y_s6 d_bz d_bP";
export var datasheetWrapperFull = "y_s7 d_cD";
export var datasheetWrapperFullLeft = "y_qT d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "y_mv d_lv";
export var contentWrapperCenter = "y_s8 d_lz";
export var datasheetLeftWrapperCenter = "y_s9 d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "y_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "y_tb d_lw";
export var datasheetFooter = "y_ly d_ly";
export var alignLeft = "y_rd d_dv";
export var alignCenter = "y_bP d_dw";
export var alignRight = "y_rf d_dx";
export var datasheetInnerWrapperNewLeft = "y_tc d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "y_td d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "y_tf d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "y_tg d_bK";
export var datasheetSubtitle = "y_th d_cx";
export var tableRow = "y_tj";
export var cell = "y_tk";
export var tableRowWrapper = "y_tl d_w d_dv";
export var tableHeadingCell = "y_tm";
export var tableHeading = "y_tn";
export var tableRowStriped = "y_tp";
export var tableRowHeading = "y_tq";
export var dataTable = "y_tr d_w";
export var imageWrapper = "y_rj d_fg d_Z";
export var imageWrapperFull = "y_ts d_H d_w d_bf d_Z";
export var imageWrapperIcon = "y_tt";
export var titleMargin = "y_tv d_cs";
export var datasheetInnerInnerWrapper = "y_tw d_w";
export var hasLabels = "y_tx";
export var label = "y_ty";
export var SmallSmall = "y_sK D_sK D_vr D_vP";
export var SmallNormal = "y_sL D_sL D_vr D_vQ";
export var SmallLarge = "y_sM D_sM D_vr D_vM";