// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "C_vb d_gv d_cs";
export var heroHeaderCenter = "C_gw d_gw d_cs d_dw";
export var heroHeaderRight = "C_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "C_vc d_gr d_cw";
export var heroParagraphCenter = "C_gs d_gs d_cw d_dw";
export var heroParagraphRight = "C_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "C_vd d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "C_vf d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "C_vg d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "C_vh d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "C_vj d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "C_vk D_vk";
export var heroExceptionNormal = "C_vl D_vl";
export var heroExceptionLarge = "C_vm D_vm";
export var Title1Small = "C_vn D_vn D_vr D_vs";
export var Title1Normal = "C_vp D_vp D_vr D_vt";
export var Title1Large = "C_vq D_vq D_vr D_vv";
export var BodySmall = "C_tJ D_tJ D_vr D_vL";
export var BodyNormal = "C_tK D_tK D_vr D_vM";
export var BodyLarge = "C_tL D_tL D_vr D_vN";