// extracted by mini-css-extract-plugin
export var alignLeft = "w_rd d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_rf d_fr d_bH d_dx";
export var comparisonContainer = "w_sN d_dX";
export var comparisonContainerFull = "w_sP d_dV";
export var comparisonFloatyBox = "w_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "w_jb d_jb d_w d_c5";
export var comparisonRow = "w_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "w_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "w_h3 d_h3 d_w";
export var comparisonBtnWrapper = "w_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "w_jk d_jk d_w";
export var comparisonImageContainer = "w_jg d_jg d_Z d_by d_w";
export var stretch = "w_sQ";
export var limit = "w_sR";
export var exceptionWeight = "w_sS D_vT";