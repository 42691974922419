// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "Q_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "Q_fR d_fR d_bz d_bJ";
export var menuDesign6 = "Q_xb d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "Q_xc d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "Q_xd d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "Q_xf d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "Q_xg d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "Q_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "Q_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "Q_xh";
export var navbarItem = "Q_nb d_bx";
export var navbarLogoItemWrapper = "Q_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "Q_xj d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "Q_xk d_gd d_by d_Z d_bs";
export var burgerToggle = "Q_xl d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "Q_xm d_gd d_by d_Z d_bs";
export var burgerInput = "Q_xn d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "Q_xp d_f3 d_w d_H";
export var burgerLine = "Q_xq d_f1";
export var burgerMenuDesign6 = "Q_xr d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "Q_xs d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "Q_xt d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "Q_xv d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "Q_xw d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "Q_xx d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "Q_xy d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "Q_xz d_bC d_bP";
export var compact = "Q_xB";
export var navDivided = "Q_xC";
export var staticBurger = "Q_xD";
export var menu = "Q_xF";
export var navbarDividedLogo = "Q_xG";
export var nav = "Q_xH";