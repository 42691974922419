// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "N_w2 d_bD";
export var storyRowWrapper = "N_hx d_hx d_bK";
export var storyLeftWrapper = "N_w3 d_bz d_bP";
export var storyWrapperFull = "N_w4 d_cD";
export var storyWrapperFullLeft = "N_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "N_mv d_hy";
export var storyLeftWrapperCenter = "N_w5 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "N_w6 d_hF";
export var storyHeader = "N_w7 d_hD d_w d_cs";
export var storyHeaderCenter = "N_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "N_hB d_hB d_by d_dw";
export var storyBtnWrapper = "N_w8 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "N_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "N_rj d_fg d_Z";
export var imageWrapperFull = "N_ts d_w d_H d_bf d_Z";
export var SubtitleSmall = "N_pd D_pd D_vr D_vD";
export var SubtitleNormal = "N_pf D_pf D_vr D_vF";
export var SubtitleLarge = "N_pg D_pg D_vr D_vG";
export var textLeft = "N_dv";
export var textCenter = "N_dw";
export var textRight = "N_dx";