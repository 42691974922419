// extracted by mini-css-extract-plugin
export var alignLeft = "z_rd d_bG";
export var alignCenter = "z_bP d_bD";
export var alignRight = "z_rf d_bH";
export var textAlignLeft = "z_tz";
export var textAlignCenter = "z_tB";
export var textAlignRight = "z_tC";
export var embedInnerWrapperDesign1 = "z_tD d_bM";
export var embed = "z_tF d_b1";
export var titleMargin = "z_tv d_cw d_dw";
export var subtitleMargin = "z_tG d_cs d_dw";
export var paragraphMargin = "z_tH d_cw d_dw";
export var SubtitleSmall = "z_pd D_pd D_vr D_vD";
export var SubtitleNormal = "z_pf D_pf D_vr D_vF";
export var SubtitleLarge = "z_pg D_pg D_vr D_vG";
export var BodySmall = "z_tJ D_tJ D_vr D_vL";
export var BodyNormal = "z_tK D_tK D_vr D_vM";
export var BodyLarge = "z_tL D_tL D_vr D_vN";