// extracted by mini-css-extract-plugin
export var socialContainer = "M_wP";
export var instagramLink = "M_wQ";
export var socialWrapperLeft = "M_qz d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "M_wR";
export var socialContentContainerFull = "M_wS";
export var instagramContainer = "M_wT";
export var twitterContainer = "M_wV";
export var facebookContainer = "M_wW";
export var socialErrorContainer = "M_wX";
export var facebookContainerWide = "M_wY";
export var twitterContainerWide = "M_wZ";
export var socialParagraphCenter = "M_w0";
export var instaWrapper = "M_w1";
export var SubtitleSmall = "M_pd D_pd D_vr D_vD";
export var SubtitleNormal = "M_pf D_pf D_vr D_vF";
export var SubtitleLarge = "M_pg D_pg D_vr D_vG";
export var textLeft = "M_dv";
export var textCenter = "M_dw";
export var textRight = "M_dx";