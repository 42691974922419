// extracted by mini-css-extract-plugin
export var campaignContainer = "v_st d_dW";
export var campaignContainerFull = "v_sv d_dT";
export var wrapper = "v_ry d_w";
export var leftWrapper = "v_sw d_bz d_bN";
export var rightWrapper = "v_sx";
export var subtitle = "v_sy";
export var title = "v_sz";
export var text = "v_pc d_cs";
export var inlineWrapper = "v_sB d_bC d_bG d_w";
export var btnsWrapper = "v_sC v_sB d_bC d_bG d_w d_bJ";
export var priceWrapper = "v_sD v_sB d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "v_sF";
export var disclaimerWrapper = "v_sG d_cD";
export var mediaSlot = "v_sH";
export var listWrapper = "v_sJ d_bC d_bP d_w";
export var btnWrapper = "v_d2";
export var imageWrapper = "v_rj d_fg d_Z";
export var SmallSmall = "v_sK D_sK D_vr D_vP";
export var SmallNormal = "v_sL D_sL D_vr D_vQ";
export var SmallLarge = "v_sM D_sM D_vr D_vM";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";
export var alignLeft = "v_rd";
export var alignCenter = "v_bP";
export var alignRight = "v_rf";